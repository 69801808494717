@import '../../Style/Partial/variables'; // Import your variable styles

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: $color__profile-white;
  padding: 20px;
  border-radius: 8px;
}

button {
  cursor: pointer;
  padding: 10px;
  background-color: $color__profile-blue;
  color: $color__profile-white;
  border: none;
  border-radius: 4px;
  margin-top: 10px;

  &:hover {
    background-color: $color__profile-dark-blue;
  }
}