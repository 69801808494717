@import '../../Style/Partial/variables'; // Import your variable styles

@include media(mobile) {
  .skills {
    background-color: $color__profile-grey;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__info {
        padding: 1rem;

        p {
            color: $color__profile-black;
            font-size: 1rem;
        }
    }
}
}

@include media(tablet) {
  .skills {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
  }
}