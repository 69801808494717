@import '../../Style/Partial/variables'; // Import your variable styles

@include media(mobile) {
  .projects {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color__profile-grey;
  }
}

@include media(tablet) {
  .projects {
    display: flex;
    flex-direction: row;
    height: fit-content;

  }
}