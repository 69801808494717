@use '../../Style/Partial/variables' as *;

@include media(mobile) {    
    .profile_pic {
      background-color: $color__profile-grey;
      height: 100%;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      margin: 0;
        &-image {
            display: flex;
            align-items: center;
          width: 100vw;
          margin: 0;
          padding:0;
        }
      
        &__info {
          padding: 1rem;
      
          p {
            color: $color__profile-black;
            font-size: 1rem;
          }
        }
      }
}
@include media(tablet){
    .profile_pic {

        width: 50%;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-start;
        &-image {
            display: flex;
width: 100%;
        }
      
      }
}
