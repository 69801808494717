@use '../../Style/Partial/variables' as *;

@include media(mobile) {

  .ProjectsDisplay {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    background-color: $color__profile-grey;
    justify-content: space-between;

    .project-item {
      margin: 0.625rem;

      width: 40vw;
      border: 0.3125rem solid $color__profile-dark-grey;
      box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.1); // Add this line for a box shadow

      cursor: pointer;

      &:hover {
        .project-title {
          color: $color__profile-dark-blue; // Highlight title on hover
        }
      }
    }

    .project-title {
      color: $color__profile-black;
      background-color: inherit;
      padding: .5rem 0.625rem .5rem 0.625rem;
      margin: 0;
      font-size: clamp(0.5rem, 3vw, 1.2rem);
      border-bottom: 5px solid $color__profile-dark-grey;
    }

    .video-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      video {
        width: 100%;
        height: auto;
      }
    }
  }
}

@include media(tablet) {
  .ProjectsDisplay {
    width: 50vw;
    background-color: $color__profile-grey;

    .project-item {
      width: 20vw;
    }
  }

}