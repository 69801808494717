// Graduate.scss
@import '../../Style/Partial/variables'; // Import your variable styles

@include media(mobile) {
  .education {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color__profile-grey; // Use your variable for background color
  }

  .education-item {
    width: 100vw;
    margin-bottom: 20px;
    background-color: $color__profile-white; // Use your variable for background color
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .education-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .education-details {
    text-align: left;

    h6 {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }

    h5 {
      font-size: 1.5rem;
      margin-bottom: 5px;
    }

    body1 {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    a {
      color: $color__profile-blue; // Use your variable for link color
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include media(tablet) {
  .education {
    justify-content: space-between;
    width: 40vw;
  }

  .education-item {
    width: 100%;
    padding: 10px;
  }

  .education-image {
    max-width: 100%;
    height: auto;
  }
}