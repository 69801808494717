@use '../../Style/Partial/variables' as *;

@include media(mobile) {

.header {
    background-color: $color__profile-dark-blue;
    color: $color__profile-white;
    height: fit-content;
    width: 98.5vw;
    padding-left: .8vw;
    padding-right: 1vw;

    .mobile-nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;

      a {
        text-decoration: none;
        color: inherit;
        font-weight: bold;
        font-size: clamp(0.5rem, 3vw, 1.2rem);
        width: auto;
      }
    }
  
}
}

@include media(tablet) {
  .header {
    .mobile-only {
      display: none;
    }
  }

}

@include media(smalldesktop) {
  .header {
    width: 99vw;
    padding-left: .5vw;
    padding-right: .5vw;
    .mobile-only {
      display: none;
    }
  }
}

@include media(desktop) {
  .header {
    width: 98vw;
    padding-left: .5vw;
    padding-right: .5vw;
    .mobile-only {
      display: none;
    }
  }
}