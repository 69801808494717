@use '../../Style/Partial/variables' as *;

@include media(mobile) {    
    .contact {
        background-color: $color__profile-grey; // Use your variable for background color
        width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      
        &__info {
          padding: 1rem;
      
          p {
            color: $color__profile-black;
            font-size: 1rem;
          }
        }
      }
}

@include media(tablet) {
  .contact {
    width: 98.5vw;
    display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
height: 100vh;
background-color: $color__profile-grey; // Use your variable for background color
height: 100%;
  }
}